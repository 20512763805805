import React, { useContext } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../components/context/BrinkContext"
import {
  containerMaxWidth,
  MEDIUM,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_X_LARGE
} from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import FilterProducts from "../components/filter/FilterProducts"

const Hero = styled(BackgroundImage)`
  width: 100%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: top center;

  ${MEDIA_MIN_MEDIUM} {
    height: 60rem;
    justify-content: flex-start;
    padding-bottom: 3rem;
  }
`

const Content = styled.div`
  max-width: ${containerMaxWidth};
  width: 100%;
  margin: 8rem auto 0;
  color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 3rem;

  ${MEDIA_MIN_MEDIUM} {
    margin: 10rem auto 0;
  }
`

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto 6rem;
  text-align: center;

  h1 {
    margin: 4rem 0 1rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 6rem 0 1rem;
    }
  }
`

const Title = styled.h1`
  text-align: center;
  padding: 0 1.3rem 0 1rem;
  line-height: 2.8rem;
  font-size: 2.8rem;
  letter-spacing: 0.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
  border-left: 0.3rem solid ${(p) => p.theme.colors.primary};
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
`

const Description = styled.h2`
  text-align: center;
  padding: 0 2rem;
  width: 100%;
  max-width: 70rem;
  line-height: 2rem;
  font-weight: 400;
  font-size: 1.3rem;
  margin: 0 auto;
  text-transform: initial;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin: 0 auto;
  }
`

const TotalProducts = styled.p`
  text-align: center;
  font-size: 1.1rem;
  margin: 0 auto;
  position: absolute;
  top: -0.4rem;
  right: 0;

  ${MEDIA_MIN_X_LARGE} {
    font-size: 1.2rem;
  }
`

const CollectionBreadcrumbs = styled(Breadcrumbs)`
  background: ${(p) => p.theme.colors.primaryOpac};
  margin-top: ${(p) => (p.hasHero ? "-4rem" : "0")};
  padding: 1.2rem 2.5rem;
  color: ${(p) => p.theme.colors.white};

  a {
    color: ${(p) => p.theme.colors.white};
  }

  ${MEDIA_MIN_X_LARGE} {
    padding: 1.8rem 0;
    margin-top: ${(p) => (p.hasHero ? "-5.2rem" : "0")};
  }
`

const CollectionPage = ({ data: { sanityCollectionPage }, pageContext }) => {
  const { t } = useTranslation("translations")
  const { languageCode } = useContext(BrinkContext)
  const {
    pageTitle,
    pageDescription,
    intSlug,
    collections,
    mobileImage,
    desktopImage,
    headerColorTheme
  } = sanityCollectionPage
  const products = collections
    .map((collection) => collection.products)
    .flat(1)
    .filter((p) => p.active && p.onlyVip !== true)
  const gender = intSlug[pageContext.buildLanguage].includes("dudes")
    ? "dudes"
    : intSlug[pageContext.buildLanguage].includes("ladies")
      ? "ladies"
      : "unisex"
  const loginEnabled = process.env.GATSBY_VIP_ENABLE_LOGIN === "true"
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  const hasHero = mobileImage && desktopImage

  const sources = hasHero
    ? [
      getImage(mobileImage.asset),
      {
        ...getImage(desktopImage.asset),
        media: `(min-width: ${MEDIUM}px)`
      }
    ]
    : null

  const description = pageDescription
    ? pageDescription[languageCode] || pageDescription.en
    : ""

  return (
    <LayoutComponent
      meta={{
        title: pageTitle[languageCode] || pageTitle.en,
        description: description
      }}
      pageContext={pageContext}
      absoluteHeader={hasHero}
      invertedHeader={!hasHero || headerColorTheme === "dark"}
    >
      {hasHero && (
        <Hero {...convertToBgImage(sources)}>
          {/* <Content>
            <Title>{pageTitle[languageCode] || pageTitle.en}</Title>
            {description && <Description>{description}</Description>}
          </Content> */}
        </Hero>
      )}
      {/* <CollectionBreadcrumbs
        hasHero={hasHero}
        breadcrumbs={
          !gender || gender === "unisex"
            ? [
              {
                slug: `/${intSlug[languageCode] || intSlug.en}/`,
                name: pageTitle[languageCode] || pageTitle.en
              }
            ]
            : gender !== intSlug.en
              ? [
                { slug: `/${gender}-home/`, name: gender },
                {
                  slug: `/${intSlug[languageCode] || intSlug.en}/`,
                  name: pageTitle[languageCode] || pageTitle.en
                }
              ]
              : [{ slug: `/${gender}-home/`, name: gender }]
        }
      >
        <TotalProducts>
          {products.length}{" "}
          {products.length === 1 ? t("product") : t("products")}
        </TotalProducts>
      </CollectionBreadcrumbs> */}
      <Container>
        {!hasHero && (
          <>
            <Title>{pageTitle[languageCode] || pageTitle.en}</Title>{" "}
            {pageDescription?.en && (
              <Description>
                {pageDescription[languageCode] || pageDescription.en}
              </Description>
            )}
          </>
        )}
        <FilterProducts
          pageSlug={intSlug.en}
          pageTitle={pageTitle.en}
          collectionProducts={products}
          gender={gender === "unisex" ? "kids" : gender}
        />
      </Container>
    </LayoutComponent>
  )
}

export default CollectionPage

export const query = graphql`
  query ($collectionPageId: String!) {
    sanityCollectionPage(_id: { eq: $collectionPageId }) {
      _id
      pageTitle {
        en
      }
      pageDescription {
        en
      }
      slug {
        current
      }
      intSlug {
        en
      }
      headerColorTheme
      mobileImage {
        asset {
          gatsbyImageData(width: 900)
        }
      }
      desktopImage {
        asset {
          gatsbyImageData(width: 2800)
        }
      }
      collections {
        title
        products {
          ...ProductSimple
        }
      }
    }
    parentCollection: sanityCollectionPage(
      childCollections: { elemMatch: { _id: { eq: $collectionPageId } } }
    ) {
      slug {
        current
      }
      intSlug {
        en
      }
      pageTitle {
        en
      }
    }
  }
`
